import { gql } from 'graphql-request';

import {
  MessageID,
  MessageBody,
  MessageUUID,
  MessageVisibleForClient,
  MessageRed,
  MessageFlagged,
  MessageColors,
  MessageFileAttachmentsUUID,
  MessageFileAttachmentsFile
} from '../messagesTypes';

export interface CreateMessageInProjectQueryResponse {
  id: MessageID;
  uuid: MessageUUID;
  body: MessageBody;
  visibleForClient: MessageVisibleForClient;
  red: MessageRed;
  flagged: MessageFlagged;
  colors: MessageColors;
  fileAttachments: {
    uuid: MessageFileAttachmentsUUID;
    file: MessageFileAttachmentsFile;
  };
}

export const CREATE_MESSAGE_IN_PROJECT_QUERY = gql`
  mutation CreateMessageInProject(
    $projectId: ID!
    $uuid: UUID
    $body: String!
    $mentionedUserIds: [ID!]
    $visibleForClient: Boolean
    $red: Boolean
    $flagged: Boolean
    $propagate: Boolean
    $pinnedAt: DateTime
    $colors: [String!]
    $fileAttachmentIds: [ID!]
    $attachedFileAttachmentIds: [ID!]
    $selectedProductIds: [ID!]
    $selectedLifestyleIds: [ID!]
    $selectedMaterialIds: [ID!]
    $todoItems: [CreateMessageInProjectTodoItem!]
    $repliedMessageId: ID
  ) {
    createMessageInProject(
      input: {
        projectId: $projectId
        uuid: $uuid
        body: $body
        mentionedUserIds: $mentionedUserIds
        visibleForClient: $visibleForClient
        red: $red
        flagged: $flagged
        propagate: $propagate
        pinnedAt: $pinnedAt
        colors: $colors
        fileAttachmentIds: $fileAttachmentIds
        attachedFileAttachmentIds: $attachedFileAttachmentIds
        selectedProductIds: $selectedProductIds
        selectedLifestyleIds: $selectedLifestyleIds
        selectedMaterialIds: $selectedMaterialIds
        todoItems: $todoItems
        repliedMessageId: $repliedMessageId
      }
    ) {
      status
      recordUuid
      record {
        id
        uuid
      }
      errors {
        projectId
        body
        visibleForClient
        red
        flagged
        propagate
        colors
        fileAttachmentIds
        attachedFileAttachmentIds
        selectedProductIds
        selectedLifestyleIds
        selectedMaterialIds
        fullMessages
      }
    }
  }
`;
